export const addresses = [
    {
        title:"GENERAL: JOSÉ LUIS SÉCULI PORTABELLA",
        name:"José Luis Séculi Portabella",
        address:"Calle Ramón y Cajal 25,",
        postalCode:"08024 Barcelona",
        isPrincipal: true
    },
    {
        title:"GENERAL: JOSÉ LUIS SÉCULI PORTABELLA",
        name:"José Luis Séculi Portabella",
        address:"Calle Ramón y Cajal 25,",
        postalCode:"08024 Barcelona",
    },
    {
        title:"GENERAL: JOSÉ LUIS SÉCULI PORTABELLA",
        name:"José Luis Séculi Portabella",
        address:"Calle Ramón y Cajal 25,",
        postalCode:"08024 Barcelona",
    },
]