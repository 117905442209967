import React, { Fragment } from 'react'
import Proptypes from 'prop-types'
import SvgIcon from "../SvgCurve"
import { Link } from 'gatsby';

export default function AddressCard(props) {
    const { title, name, address, postalCode, isPrincipal } = props;

    const renderPrincipal = () => {
        if (!isPrincipal) return <Fragment />

        return <span className="AddressCard-principal">Has marcado esta dirección como la principal</span>
    }

    return (
        <div className="AddressCard">
            <div className="AddressCard-header">
                <span className="AddressCard-title">{title}</span>
                <div className="AddressCard-actions">
                    <Link className="AddressCard-action">
                        <SvgIcon className="AddressCard-icon" icon="editIcon" />
                         Editar
                    </Link>
                    <div className="AddressCard-action">
                        <SvgIcon className="AddressCard-icon" icon="trashIcon" />
                         Eliminar
                    </div>
                </div>
            </div>
            <div className="AddressCard-body">
                <span className="AddressCard-text">{name}</span>
                <span className="AddressCard-text">{address}</span>
                <span className="AddressCard-text">{postalCode}</span>
                {renderPrincipal()}
            </div>
        </div>
    )
}

AddressCard.defaultProps = {
    title: "",
    name: "",
    address: "",
    postalCode: "",
    isPrincipal: false,
}

AddressCard.propTypes = {
    title: Proptypes.string,
    name: Proptypes.string,
    address: Proptypes.string,
    postalCode: Proptypes.string,
    isPrincipal: Proptypes.bool
}
