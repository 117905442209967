import React from 'react'
import Layout from "../../components/Layout"
import AccountLayout from "../../components/Account/AccountLayout"
import SvgIcon from "../../components/SvgCurve";
import { Link } from 'gatsby';
import AddressCard from "../../components/AddressCard"
import { addresses } from "../../data/address-data"

export default function Addresses() {

    const renderAddressesCards = () => {
        return addresses&&addresses.map((item)=> {
            return(
                <AddressCard
                    title={item.title}
                    name={item.name}
                    address={item.address}
                    postalCode={item.postalCode}
                    isPrincipal={item.isPrincipal}
                />
            )
        })
    }
    return (
        <Layout>
            <AccountLayout>
                <div className="Addresses">
                    <div className="Addresses-header">
                        <span className="Addresses-title">
                            <SvgIcon className="Addresses-icon" icon="addresses" />
                            Mis direcciones
                        </span>
                        <Link to="/privado/add-address" className="Addresses-link">Añadir nueva dirección</Link>
                    </div>
                    {renderAddressesCards()}
                </div>
            </AccountLayout>
        </Layout>
       
    )
}
